import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from './connectors';

export default function DisconnectModal(props) {
  const context = useWeb3React();
  const { deactivate } = context;
  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);

  useEffect(() => {
    setDisconnectModalOpen(props.show);
  }, [props]);

  const onWalletDisconnect = async () => {
    deactivate(injected);
    props.onClose();
  };

  return (
    <>
      <div className='modal'>
        <div className='modal-content'>
          <p>Are you sure you want to disconnect your wallet?</p>
          <button className='button' onClick={onWalletDisconnect}>
            Disconnect
          </button>
        </div>
      </div>
    </>
  );
}
