import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { MerkleTree } from 'merkletreejs';
import { ethers } from 'ethers';
import axios from 'axios';
import keccak256 from 'keccak256';
import addresses from './files/addresses2.json';

import abi from './contracts/abi.json';
import genesisAbi from './contracts/genesisAbi.json';

const RPC = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
const provider = new ethers.providers.JsonRpcProvider(RPC);

const genesisContract = '0xd3605059c3cE9fACf625Fa72D727508B7b7F280F';
const contractAddress = '0xbAabB2E7F044e4F8D77A3C15FDD9F671492Bfd1f';

function getMaxQty(qty) {
  let allowedQuantityToMint = 0;
  if (qty >= 400) {
    allowedQuantityToMint = 200;
  } else if (qty >= 200) {
    allowedQuantityToMint = 100;
  } else if (qty >= 50) {
    allowedQuantityToMint = 50;
  } else if (qty >= 50) {
    allowedQuantityToMint = 16;
  } else if (qty >= 25) {
    allowedQuantityToMint = 8;
  } else if (qty >= 10) {
    allowedQuantityToMint = 4;
  } else if (qty >= 5) {
    allowedQuantityToMint = 2;
  } else if (qty >= 1) {
    allowedQuantityToMint = 1;
  }
  return allowedQuantityToMint;
}

function ClaimModal(props) {
  const context = useWeb3React();
  const [pablos, setPablos] = useState(null);
  const [maxQty, setMaxQty] = useState(0);
  const [whitelistQty, setWhitelistQty] = useState(0);
  const [isBuy, setIsBuy] = useState(false);
  const [selectedPablos, setSelectedPablos] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hexProof, setHexProof] = useState([]);

  useEffect(() => {
    const res = axios.get(
      `https://vw7zo7vdxa.execute-api.us-east-2.amazonaws.com/proof/${context.account}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((data) => setHexProof(data.data));
  }, [context?.account]);

  const onPixelate = async () => {
    const gasPrice = Number(await provider.getGasPrice());

    console.log('hexProof', hexProof);

    const { library } = context;
    const signer = library.getSigner();
    const contract = new ethers.Contract(
      contractAddress,
      abi,
      signer,
      {
        from: context.account,
        gasPrice: gasPrice,
      },
    );
    try {
      const tx = await contract.claim(
        selectedPablos,
        hexProof,
        {
          value: ethers.utils.parseEther('0.00'),
        },
      );
      console.log(tx);
    } catch (error) {
      if (error.message.includes('InvalidMerkleProof')) {
        setErrorMessage(`${context.account} is not whitelisted`);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  useEffect(() => {
    if (
      selectedPablos?.length > 0 &&
      selectedPablos?.length <= maxQty
    ) {
      setIsBuy(true);
    } else {
      setIsBuy(false);
    }
  }, [selectedPablos, maxQty]);

  useEffect(() => {
    const ping = async () => {
      const contract = new ethers.Contract(contractAddress, abi, provider);
      const qty = await contract.whitelistedAddresses(context.account);
      setWhitelistQty(Number(qty));
    };
    ping();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let pablos = [];
      const response = await axios(
        `https://deep-index.moralis.io/api/v2/${context.account}/nft?chain=eth&format=decimal&token_addresses=0xd3605059c3ce9facf625fa72d727508b7b7f280f`,
        {
          headers: {
            'X-API-Key':
              'czyhQhe3I7NUFeEfD4uQYy7hMOy3N6N6uQvcPw85FFyAErXfsnap04ny3X37yUsa',
            accept: 'application/json',
          },
        },
      );
      let cursor = response.data.cursor;
      const total = response.data.total;
      const data = response.data.result;
      const tokenIds = data.map((token) => parseInt(token['token_id']));
      pablos = pablos.concat(tokenIds);
      while (pablos.length < total) {
        const response = await axios(
          `https://deep-index.moralis.io/api/v2/${context.account}/nft?chain=eth&format=decimal&token_addresses=0xd3605059c3ce9facf625fa72d727508b7b7f280f&cursor=${cursor}`,
          {
            headers: {
              'X-API-Key':
                'czyhQhe3I7NUFeEfD4uQYy7hMOy3N6N6uQvcPw85FFyAErXfsnap04ny3X37yUsa',
              accept: 'application/json',
            },
          },
        );
        cursor = response.data.cursor;
        const data = response.data.result;
        const tokenIds = data.map((token) => parseInt(token['token_id']));
        pablos = pablos.concat(tokenIds);
      }
      setPablos(pablos);
    };

    fetchData();
  }, [context]);

  const onModalClose = async () => {
    props.onClose();
  };

  const onThumbnailClick = (id) => {
    if (selectedPablos.includes(id)) {
      setSelectedPablos(selectedPablos.filter((pablo) => pablo !== id));
    } else {
      setSelectedPablos([...selectedPablos, id]);
    }
  };

  useEffect(() => {
    setMaxQty(getMaxQty(pablos?.length) - whitelistQty);
  }, [pablos]);

  console.log(selectedPablos);
  console.log(whitelistQty);
  console.log('isBuy', isBuy);

  return (
    <div className='modal'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>
            Pixelation
          </h2>
          <span>Choose your Pablos to pixelate!</span>
          <span className='close' onClick={onModalClose}>
            X (CLOSE)
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '1rem',
            }}
          >
            <button
              className={`button`}
              disabled={!isBuy}
              onClick={onPixelate}
            >
              PIXELATE
            </button>
          </div>
          <div
            className={`counter ${
              selectedPablos?.length > maxQty && 'overboard'
            }`}
          >
            {selectedPablos?.length}/{maxQty}
          </div>
          {errorMessage && <div className='error'>{errorMessage}</div>}
        </div>
        <div className='modal-body'>
          {pablos?.length > 0
            ? pablos?.map((id) => {
              const url =
                `https://img.x2y2.io/v2/1/0xd3605059c3ce9facf625fa72d727508b7b7f280f/${id}/280/static.jpg`;
              return (
                <div
                  className='thumbnail'
                  onClick={() => onThumbnailClick(id)}
                >
                  <img
                    className={`${
                      selectedPablos.includes(id) ? 'selected' : ''
                    }`}
                    key={id}
                    height={75}
                    width={75}
                    src={url}
                  />
                  <span>{id}</span>
                </div>
              );
            })
            : <div>You don't own a genesis Pablo</div>}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ClaimModal);
