import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { useEagerConnect } from './hooks/useEagerConnect';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ethers } from 'ethers';
import DisconnectModal from './DisconnectModal';
import InputNumber from './InputNumber';
import ClaimModal from './ClaimModal';
import abi from './contracts/abi.json';
import { injected } from './connectors';

const contractAddress = '0xbAabB2E7F044e4F8D77A3C15FDD9F671492Bfd1f';
const RPC = 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
const provider = new ethers.providers.JsonRpcProvider(RPC);

// useEffect(() => {
//   const contract = new ethers.Contract(contractAddress, abi, provider);
//   async function getPablosExist() {
//     let ids = [];
//     for await (const pablo of pablos) {
//       const exist = await contract.exists(pablo);
//       if (exist) {
//         ids.push(pablo);
//       }
//     }
//     setPablosExist(pablosExist);
//   }
//   getPablosExist();
// }, [pablos]);
//

export default function Home() {
  const context = useWeb3React();
  const { activate, deactivate, active, error } = context;
  const [isDisconnectModalOpen, setDisconnectModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [isPublicReady, setIsPublicReady] = useState(false);
  const [isHoldersReady, setIsHoldersReady] = useState(false);
  const [isClaimModalOpen, setClaimModalOpen] = useState(false);
  const [qtyRequested, setQtyRequested] = useState(1);

  useEffect(() => {
    const ping = async () => {
      const contract = new ethers.Contract(contractAddress, abi, provider);

      const _isHoldersReady = await contract.isHoldersActive();
      const _isPublicReady = await contract.isPublicActive();
      const _totalSupply = await contract.totalSupply();
      const _maxSupply = await contract.MAX_MINT_SUPPLY();

      console.log(_isPublicReady);

      setTotalSupply(Number(_totalSupply));
      setIsPublicReady(_isPublicReady);
      setIsHoldersReady(_isHoldersReady);
      setMaxSupply(Number(_maxSupply));
    };
    setInterval(ping, 2000);
  }, []);

  const onGenesisMint = async () => {
    if (context.account) {
      setClaimModalOpen(true);
    } else {
      setErrorMessage('Connect your wallet first');
    }
  };

  const publicMint = async () => {
    if (!context.account) {
      setErrorMessage('Connect your wallet first');
    }
    const { library } = context;
    const signer = library.getSigner();
    const gasPrice = Number(await provider.getGasPrice());
    const contract = new ethers.Contract(
      contractAddress,
      abi,
      signer,
      {
        from: context.account,
        gasPrice: gasPrice,
      },
    );
    try {
      const tx = await contract.mint(qtyRequested, {
        value: ethers.utils.parseEther((qtyRequested * 0.01).toString()),
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onWalletConnect = async () => {
    if (window.ethereum == undefined) {
      window.open('https://metamask.io/', '_blank');
      setErrorMessage(
        'you need metamask or any other dapp extension to connect',
      );
    }
    activate(injected);
  };

  const onWalletDisconnect = async () => {
    setDisconnectModalOpen(true);
    console.log(isDisconnectModalOpen);
  };

  return (
    <div className='App'>
      <main className='App-main'>
        <div className='grid'>
          <div className='grid-item menu'>
            <div className='App-navbar__right'>
              {active && (
                <button className='button' onClick={onWalletDisconnect}>
                  {context.account.substring(0, 5)}...
                  {context.account.substring(context.account.length - 4)}
                </button>
              )}
              {!active && (
                <button className='button' onClick={onWalletConnect}>
                  Connect Wallet
                </button>
              )}
              <button
                className='button mints'
                onClick={onGenesisMint}
                disabled={!isHoldersReady}
              >
                <div className='button-container'>
                  <span className='title'>
                    GENESIS HOLDER MINT
                    <div className='button-floater'>FREE MINT</div>
                  </span>
                </div>
              </button>
              {isPublicReady
                ? (
                  <div className='mintButton'>
                    <button
                      className='button mints'
                      onClick={publicMint}
                      disabled={!isPublicReady}
                    >
                      <div className='button-container'>
                        <span className='title'>
                          PUBLIC MINT
                          <div className='button-floater'>
                            {qtyRequested * 0.01} ETH
                          </div>
                        </span>
                      </div>
                    </button>
                    <InputNumber
                      value={qtyRequested}
                      onChange={setQtyRequested}
                      min={1}
                      max={200}
                    />
                  </div>
                )
                : (
                  <button
                    className='button mints'
                    onClick={publicMint}
                    disabled={!isPublicReady}
                  >
                    <div className='button-container'>
                      <span className='title'>
                        PUBLIC MINT
                        <div className='button-floater'>0.01 ETH</div>
                      </span>
                    </div>
                  </button>
                )}
              {errorMessage && (
                <div className='error-message'>{errorMessage}</div>
              )}
              <div>
                <h1 className='supply'>
                  {maxSupply === 0
                    ? 'Fetching supply'
                    : maxSupply - totalSupply + ' LEFT OUT OF ' + maxSupply}
                </h1>
              </div>
            </div>
          </div>
          <div className='grid-item logo'>
            <img src='/logo.png' style={{ maxWidth: '75%' }} />
            <img src='/sliqgardenlozax.gif' style={{ maxWidth: '150%' }} />
          </div>
        </div>
      </main>
      {isDisconnectModalOpen && (
        <DisconnectModal
          show={isDisconnectModalOpen}
          onClose={() => setDisconnectModalOpen(false)}
        />
      )}
      {isClaimModalOpen &&
        (
          <ClaimModal
            onClose={() => setClaimModalOpen(!isClaimModalOpen)}
          />
        )}
    </div>
  );
}
